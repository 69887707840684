import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance-widget',
  templateUrl: './maintenance-widget.component.html',
  styleUrls: ['./maintenance-widget.component.scss']
})
export class MaintenanceWidgetComponent {

}
