<!-- <footer class="footer-area">
  <div class="container">
    <div class="row">

      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let section of footerSections">
        <div class="single-footer-widget">
          <h3>{{section.title}}</h3>

          <ul class="links-widget-list" *ngIf="section.items">
            <li *ngFor="let item of section.items">
              <a routerLink="{{item.url}}">{{item.description}}</a>
            </li>
          </ul>

          <div class="widget-contact-info" *ngIf="section.contactInfo">
            <p>{{section.contactInfo}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>


</footer> -->

<footer class="footer-area" *ngIf="validate()">
  <div class="container" [ngClass]="data.class" loading="lazy">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let section of data?.footerSections">
        <div class="single-footer-widget">
          <h3>{{section.title}}</h3>

          <div *ngIf="section.newsletter">
            <div class="logo">
              <a routerLink="/"><img [src]="section.logo" alt="image"></a>
              <p>{{section.description}}</p>
            </div>
            <form class="newsletter-form">
              <input type="email" class="newsletter-input" placeholder="Your Email Address" name="email">
              <button type="submit"><i class="flaticon-paper-plane"></i></button>
            </form>
          </div>

          <ul class="links-widget-list" *ngIf="section.items">
            <li *ngFor="let item of section.items">
              <a routerLink="{{item.url}}">{{item.description}}</a>
            </li>
          </ul>

          <div class="widget-contact-info" *ngIf="section.contactInfo">
            <p>{{section.contactInfo}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
        <p>© Vimi is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
    </div>
</div>
</footer>


