
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';




export const API_URI = environment.base_uri;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public nameEmitter = new ReplaySubject();
  public nameObservable = this.nameEmitter.asObservable();
  constructor(private _router: Router, private _http: HttpClient) {}

  /** POST REQUEST */
  public post(url: string, params: any): Observable<any> {
    return this._http.post(`${API_URI}/${url}`, params, this._getOptions());
  }

    /** GET REQUEST */
    public get(url: string): Observable<any> {
      return this._http.get(`${API_URI}/${url}`, this._getOptions());
    }

    public getParams(url: string, params: any): Observable<any> {
      let options = {
        params: new HttpParams()
      };
      for (let param in params) {
        options.params = options.params.set(param, params[param]);
      }
      return this._http.get(`${API_URI}/${url}`, {...this._getOptions(), ...options});
    }


/** DELETE REQUEST */
    public delete(url: string, uuid: string): Observable<any> {
      return this._http.delete(`${API_URI}/${url}/${uuid}`, this._getOptions());
    }

  public logout(): void {
    this.post('logout', {}).subscribe({
      next: (response: any) => {
        console.log('ApiService logout response', response);
        localStorage.removeItem('token');
        this._router.navigate(['login']).then(() => {
          window.location.href = window.location.href;
        });
      },
      error: (error: any) => {
        console.log('ApiService logout error', error);
        localStorage.removeItem('token');
      },
    });
  }

  public async getName(): Promise<any> {
    try {
      const name = await lastValueFrom(this.nameEmitter);
      if (!name) this.logout();
      return name;
    } catch (error) {
      this.logout();
      return null;
    }
  }

  public postWithFullResponse(url: string, params: any): Observable<HttpResponse<any>> {
    let token: string | null = localStorage.getItem('token');
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json',
    };
    return this._http.post<HttpResponse<any>>(`${API_URI}/${url}`, params, httpOptions);
  }
  
  public get referrer() {
    return document.referrer;
  }

    /** HTTP HEADERS */
    private _getOptions() {
      let token: string | null = localStorage.getItem('token');
      let httpOptions = {
        headers: new HttpHeaders({
        observe: 'response ',
        responseType: 'json',
        Authorization: 'Bearer ' + token,
        }),
      };
      return httpOptions;
    }
}
