import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-card',
  templateUrl: './single-card.component.html',
  styleUrls: ['./single-card.component.scss'],
})
export class SingleCardComponent {
  @Input() data: any;

  validate(): boolean {
    return (
      !!this.data &&
      !!this.data.header &&
      !!this.data.listItems &&
      !!this.data.detail &&
      !!this.data.buttons &&
      !!this.data.images
    );
  }
  /*   pricingTables = {
    icon: 'flaticon-online-shop',
    category: 'TV + Internet',
    title: 'Player Bundle',
    features: [
      '150+ channels',
      'Catch Up & On Demand',
      'Cell Phone Connection',
      'Up to 100Mbps fibre',
      'Made for 1-4 devices',
    ],
    price: '67.99',
    link: '/',
    images: ['assets/img/shape-image/2.png', 'assets/img/shape-image/2.png'],
    textbutton: 'View Plans'
  }; */
}
