import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpResponse } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './modules/widgets/footer/footer.component';
import { NavbarComponent } from './modules/widgets/navbar/navbar.component';
import { MainBannerContentComponent } from './modules/widgets/main-banner-content/main-banner-content.component';
import { BannerImageComponent } from './modules/widgets/banner-image/banner-image.component';
import { SectionTitleComponent } from './modules/widgets/section-title/section-title.component';
import { SingleCardComponent } from './modules/widgets/single-card/single-card.component';
import { FaqAccordionComponent } from './modules/widgets/faq-accordion/faq-accordion.component';
import { SingleBoxComponent } from './modules/widgets/single-box/single-box.component';
import { AboutContentComponent } from './modules/widgets/about-content/about-content.component';
import { AboutImageComponent } from './modules/widgets/about-image/about-image.component';
import { SingleCardPostComponent } from './modules/widgets/single-card-post/single-card-post.component';
import { SingleServicesBoxComponent } from './modules/widgets/single-services-box/single-services-box.component';
import { MaintenanceWidgetComponent } from './modules/widgets/maintenance-widget/maintenance-widget.component';
import { NavbarTwoComponent } from './modules/widgets/navbar-two/navbar-two.component';
import { TsHeaderOneComponent } from './modules/widgets/ts-header-one/ts-header-one.component';
import { TsHomeOneComponent } from './modules/widgets/ts-home-one/ts-home-one.component';
import { TsFooterComponent } from './modules/widgets/ts-footer/ts-footer.component';
import { TsPostsComponent } from './modules/widgets/ts-posts/ts-posts.component';
import { TsFaqComponent } from './modules/widgets/ts-faq/ts-faq.component';
import { TsTitleComponent } from './modules/widgets/ts-title/ts-title.component';
import { TsCtaComponent } from './modules/widgets/ts-cta/ts-cta.component';
import { TsSingleCardComponent } from './modules/widgets/ts-single-card/ts-single-card.component';
import { TsSingleBoxComponent } from './modules/widgets/ts-single-box/ts-single-box.component';
import { TsOfferWidgetComponent } from './modules/widgets/ts-offer-widget/ts-offer-widget.component';
import { TsImageComponent } from './modules/widgets/ts-image/ts-image.component';
import { TsAboutContentComponent } from './modules/widgets/ts-about-content/ts-about-content.component';
import { FormsWidgetComponent } from './modules/widgets/forms-widget/forms-widget.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from "ngx-loading";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './interceptors/auth.interceptors';
import { AuthService } from './services/auth.service';

 export function initializeApp(authService: AuthService) {
  return (): Promise<void> => {
    const token = localStorage.getItem('token');
    if (!token) {
      return authService.authenticate();
    } else {
      return Promise.resolve();
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    MainBannerContentComponent,
    BannerImageComponent,
    SectionTitleComponent,
    SingleCardComponent,
    FaqAccordionComponent,
    SingleBoxComponent,
    AboutContentComponent,
    AboutImageComponent,
    SingleCardPostComponent,
    SingleServicesBoxComponent,
    MaintenanceWidgetComponent,
    NavbarTwoComponent,
    TsHeaderOneComponent,
    TsHomeOneComponent,
    TsFooterComponent,
    TsPostsComponent,
    TsFaqComponent,
    TsTitleComponent,
    TsCtaComponent,
    TsSingleCardComponent,
    TsSingleBoxComponent,
    TsOfferWidgetComponent,
    TsImageComponent,
    TsAboutContentComponent,
    FormsWidgetComponent,

  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, ReactiveFormsModule, NgxLoadingModule],
  providers: [
    AuthService,
     {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, HttpClient, Router],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
