<div class="single-features-box fixed-size-box" [ngClass]="data.class" loading="lazy">
  <div class="icon">
     <i [class]="data.icon.class"></i>
  </div>
  <h3>{{data.title.text}}</h3>
  <p *ngFor="let paragraph of data.paragraphs" [ngClass]="paragraph.class" [innerHTML]="paragraph?.text"></p>
  <button *ngIf="data?.button.text"
  (click)="navigate(data.button.link)"
  [ngClass]="data.button.class"  >{{data.button.text}}</button>
</div>
