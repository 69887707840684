<div class="main-banner-content" *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <span class="sub-title"><i class="flaticon-wifi-signal"></i> {{data.subTitle.text}}</span>
  <h1>{{data.title.text}}</h1>
  <p *ngFor="let paragraph of data.paragraphs" [ngClass]="paragraph.class">{{paragraph.text}}</p>
  <div class="price">
      {{data.price}}
  </div>
  <div class="btn-box">
    <a *ngFor="let button of data.buttons" [routerLink]="button.link" class="{{button.class}}">{{button.text}}</a>
    <a *ngFor="let button of data.youtubeButtons" [href]="button.href" class="{{button.class}}"><i class="{{button.iconClass}}"></i></a>
  </div>

</div>
