<div class="cta-area" loading="lazy" [ngClass]="data.class">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-10 col-md-10 col-10 offset-1 p-0">
                <div class="cta-content offset-0 ts-cta-content">
                    <h2 [ngClass]="data.title.class">{{data.title.text}}</h2>
                   <a *ngFor="let item of data.phoneNumber" [href]="item.link" [ngClass]="item.class">
                      <i *ngIf="item.icon" class="{{item.icon}}" aria-hidden="true"></i> 
                      {{item.text}}
                    </a>
                </div>
            </div>
  
            <div class="col-lg-4 col-md-12" *ngIf="data.buttonText.text">
                <div class="cta-btn">
                    <a [routerLink]="data.buttonLink.text" [ngClass]="data.buttonLink.class" class="btn btn-primary btn-green-ts">{{data.buttonText.text}}</a>
                </div>
            </div>
        </div>
    </div>
  </div>