<header class="header-area" >
  <div class="top-header" [ngClass]="data.class" loading="lazy">
      <div class="container">
          <div class="row align-items-center">
              <div class="col-lg-5 col-md-5">
                  <div class="top-header-left">
                      <p><span>{{data?.topHeader?.text}}</span> <a href="">{{data?.topHeader?.phoneNumber}}</a></p>
                  </div>
              </div>

              <div class="col-lg-7 col-md-7">
                  <div class="top-header-right">
                      <div class="login-signup-btn">
                          <p><a [routerLink]="data?.topHeader?.loginUrl">Login</a> <span>or</span> <a [routerLink]="data?.topHeader?.registerUrl">Register</a></p>
                      </div>
                      <ul class="social">
                          <li *ngFor="let link of data?.topHeader?.socialLinks"><a [href]="link.url" target="_blank"><i class="fab fa-{{link.platform}}"></i></a></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>
