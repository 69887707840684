import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent {
  @Input() data: any;

  validate(): boolean {
    return !!this.data && !!this.data.icon && !!this.data.subTitle && !!this.data.title;
  }
/*   sectionTitle = {
    subTitle: 'Vimi ISP Features',
    title: 'We are internet service provider company in United States',
    icon: 'flaticon-wifi'
  } */
}
