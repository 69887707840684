<div class="single-services-box" *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <div class="icon">
    <i [className]="data?.icon.class"></i>
  </div>
  <h3>{{data?.title.text}}</h3>
  <p *ngFor="let paragraph of data.paragraphs" [ngClass]="paragraph.class">{{paragraph.text}}</p>
  <a *ngFor="let button of data?.buttons" [routerLink]="button.link" class="read-more-btn">
    {{button.textButton}} <i class="flaticon-arrow-pointing-to-right"></i>
</a>

</div>
