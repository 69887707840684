<div class="container g-0 mb-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Campo: Nombre -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group mb-3">
          <label for="first_name">Nombre:</label>
          <input type="text" id="first_name" name="first_name" formControlName="first_name" class="form-control" placeholder="Nombre">
          <div *ngIf="form.controls['first_name'].invalid && (form.controls['first_name'].dirty || form.controls['first_name'].touched)" class="text-danger">
            <div *ngIf="form.controls['first_name'].errors?.['required']">Nombre es requerido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Apellido -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="last_name">Apellido:</label>
          <input type="text" id="last_name" name="last_name" formControlName="last_name" class="form-control" placeholder="Apellido">
          <div *ngIf="form.controls['last_name'].invalid && (form.controls['last_name'].dirty || form.controls['last_name'].touched)" class="text-danger">
            <div *ngIf="form.controls['last_name'].errors?.['required']">Apellido es requerido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Dirección -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="address">Dirección:</label>
          <input type="text" id="address" name="address" formControlName="address" class="form-control" placeholder="Dirección">
          <div *ngIf="form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)" class="text-danger" placeholder="Dirección">
            <div *ngIf="form.controls['address'].errors?.['required']">Dirección es requerida.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Teléfono -->
      <div class="col-12 col-md-6 col-lg-4">
        <label for="phone">Telefóno:</label>
        <input type="tel" id="phone" name="phone" formControlName="phone" class="form-control" placeholder="Teléfono">
        <div *ngIf="form.controls['phone'].invalid && (form.controls['phone'].dirty || form.controls['phone'].touched)" class="text-danger">
          <div *ngIf="form.controls['phone'].errors?.['required']">Teléfono es requerido.</div>
        </div>
      </div>
      <!-- Campo: Correo -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="email">Correo:</label>
          <input type="email" id="email" name="email" formControlName="email" class="form-control" placeholder="Correo">
          <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="text-danger">
            <div *ngIf="form.controls['email'].errors?.['required']">Correo es requerido.</div>
            <div *ngIf="form.controls['email'].errors?.['email']">Correo no es válido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Cédula -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="identification_number">Cedula:</label>
          <input type="text" id="identification_number" name="identification_number" formControlName="identification_number" class="form-control" placeholder="Cédula">
          <div *ngIf="form.controls['identification_number'].invalid && (form.controls['identification_number'].dirty || form.controls['identification_number'].touched)" class="text-danger">
            <div *ngIf="form.controls['identification_number'].errors?.['required']">Cédula es requerida.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Edificio -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="building">Edificio:</label>
          <input type="text" id="building" name="building" formControlName="building" class="form-control" placeholder="Edificio">
          <div *ngIf="form.controls['building'].invalid && (form.controls['building'].dirty || form.controls['building'].touched)" class="text-danger">
            <div *ngIf="form.controls['building'].errors?.['required']">Edificio es requerido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Apartamento -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="apartment">Apartamento:</label>
          <input type="text" id="apartment" name="apartment" formControlName="apartment" class="form-control" placeholder="Apartamento">
          <div *ngIf="form.controls['apartment'].invalid && (form.controls['apartment'].dirty || form.controls['apartment'].touched)" class="text-danger">
            <div *ngIf="form.controls['apartment'].errors?.['required']">Apartamento es requerido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Plan -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="plan_uuid">Plan:</label>
          <select id="plan_uuid" name="plan_uuid" formControlName="plan_uuid" class="form-control">
            <option value="" disabled selected>Seleccione su plan</option>
            <option *ngFor="let plan of plans" [value]="plan.uuid">{{ plan.name }}</option>
          </select>
          <div *ngIf="form.controls['plan_uuid'].invalid && (form.controls['plan_uuid'].dirty || form.controls['plan_uuid'].touched)" class="text-danger">
            <div *ngIf="form.controls['plan_uuid'].errors?.['required']">Plan es requerido.</div>
          </div>
        </div>
      </div>
      <!-- Campo: Vendedor -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="seller_uuid">Vendedor:</label>
          <select  id="seller_uuid" name="seller_uuid" formControlName="seller_uuid" class="form-control">
            <option value="" disabled selected>Seleccione su vendedor</option>
            <option *ngFor="let seller of sellers" [value]="seller.uuid">{{ seller.full_name }}</option>
          </select>
          <div *ngIf="form.controls['seller_uuid'].invalid && (form.controls['seller_uuid'].dirty || form.controls['seller_uuid'].touched)" class="text-danger">
            <div *ngIf="form.controls['seller_uuid'].errors?.['required']">Vendedor es requerido.</div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn ts-btn-green-content " type="submit" [disabled]="isButtonDisabled">Enviar</button>
  </form>
</div>
<!--  <div class="container g-0 mb-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div [class]="field.colClass" *ngFor="let field of data.fields">
        <label [for]="field.id">{{ field.label }}:</label>
        <input *ngIf="field.type !== 'select'" [type]="field.type" [id]="field.id" [name]="field.id" [class]="field.class" [placeholder]="field.placeholder" formControlName="{{field.id}}">
        <select *ngIf="field.type === 'select'" [id]="field.id" [name]="field.id" [class]="field.class" formControlName="{{field.id}}">
          <option *ngFor="let option of field.options" [value]="option.value">{{ option.text }}</option>
        </select>
      </div>
    </div>
    <button type="submit" [class]="data.button.class">{{ data.button.text }}</button>
  </form>
</div>
 -->

 <ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
></ngx-loading>
