import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-about-image',
  templateUrl: './about-image.component.html',
  styleUrls: ['./about-image.component.scss']
})
export class AboutImageComponent {
  @Input() data: any;
  validate(): boolean {
    return this.data && this.data.url;
}
  /* urlImagen = 'assets/img/about-img1.png' */
}
