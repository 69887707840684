import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-faq',
  templateUrl: './ts-faq.component.html',
  styleUrls: ['./ts-faq.component.scss']
})
export class TsFaqComponent {
  @Input() data: any;
}
