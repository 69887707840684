import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isSmallScreen = new BehaviorSubject<boolean>(window.innerWidth < 991);

  constructor() {
    window.onresize = () => this.isSmallScreen.next(window.innerWidth < 991);
  }

  isMobile() {
    return this.isSmallScreen.asObservable();
  }
}
