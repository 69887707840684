<div class="faq-accordion" loading="lazy" [ngClass]="data.class">
  <ul class="accordion">
    <ng-container *ngFor="let item of data.faqItems">
      <li class="accordion-item" *ngIf="item.title">
        <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add ts-bg-icon" [ngClass]="item.class"></i> {{item.title}}</a>
        <p class="accordion-content">{{item.content}}</p>
      </li>
    </ng-container>
  </ul>
</div>
