import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss'],
})
export class BannerImageComponent {
  @Input() data: any;
  validate(): boolean {
    return this.data && this.data.url;
}
 /*  urlImagen = 'assets/img/about-img1.png'; */
}
