import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-footer',
  templateUrl: './ts-footer.component.html',
  styleUrls: ['./ts-footer.component.scss']
})
export class TsFooterComponent {
  @Input() data: any;

  validate(): boolean {
    return !!this.data?.footerSections && this.data.footerSections.length > 0;
  }
  isExternalUrl(url: string) {
/*     console.log('isExternalUrl', url); */
    return url && (url.startsWith('http://') || url.startsWith('https://'));

  }
}
