import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-banner-content',
  templateUrl: './main-banner-content.component.html',
  styleUrls: ['./main-banner-content.component.scss'],
})
export class MainBannerContentComponent {
  @Input() data: any;

  validate(): boolean {
    if (!this.data) {
      return false;
    }
    if (!this.data.subTitle || !this.data.title || !this.data.paragraphs || !this.data.buttons || !this.data.youtubeButtons) {
      return false;
    }
    if (this.data.subTitle.text.trim() === '' || this.data.title.text.trim() === '' || this.data.paragraphs.length === 0 || this.data.buttons.length === 0 || this.data.youtubeButtons.length === 0) {
      return false;
    }
    return true;
  }
/*
  mainBannerContent = {
    subTitle: 'Vimi Broadband Service',
    title: 'Built for Internet Service',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis.',
    price: '$45 /mo',
    buttons: [
      { text: 'Request A Quote', routerLink: '/contact' },
      {
        text: 'Watch Video',
        href: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
      },
    ],
  }; */
}
