import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-box',
  templateUrl: './single-box.component.html',
  styleUrls: ['./single-box.component.scss'],
})
export class SingleBoxComponent {
  @Input() data: any;
  validate(): boolean {
    return !!this.data && !!this.data.icon && !!this.data.title && !!this.data.paragraphs && !!this.data.images;
  }
/*   singleBox = {
    title: 'Vimi ISP Features',
    content: 'Lorem ipsum dolor sit do eiusmod sit consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.',
    icon: 'flaticon-speedometer',
    image: 'assets/img/shape-image/2.png'
  }; */
}
