<div class="section-title d-flex flex-column align-items-center"  loading="lazy" *ngIf="validate()" [ngClass]="data.class">
  <span >
      <span class="icon">
          <i [ngClass]="data?.icon.class"></i>
      </span>
      <span [ngClass]="data?.subTitle.class">{{data?.subTitle.text}}</span>
  </span>
  <h2 [ngClass]="data?.title.class">{{data?.title.text}}</h2>
  <ng-container *ngIf="data?.paragraphs">
    <p *ngFor="let paragraph of data.paragraphs" [ngClass]="paragraph.class">{{paragraph.text}}</p>
  </ng-container>

</div>
