import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-cta',
  templateUrl: './ts-cta.component.html',
  styleUrls: ['./ts-cta.component.scss']
})
export class TsCtaComponent {
  @Input() data: any;
}
