import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-about-content',
  templateUrl: './ts-about-content.component.html',
  styleUrls: ['./ts-about-content.component.scss']
})
export class TsAboutContentComponent {
  @Input() data: any;
}
