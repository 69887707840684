<header class="header-area" >

  <div class="navbar-area" [ngClass]="data.class" loading="lazy">
    <div class="vimi-mobile-nav">
      <div class="logo">
        <a routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>
      </div>
    </div>

    <div class="vimi-nav">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand" routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>

          <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
            <ul class="navbar-nav">
              <li class="nav-item" *ngFor="let item of data.menu">
                <a [routerLink]="item.url ? item.url : 'javascript:void(0)'" class="nav-link">{{item.menu}} <i
                    class="fas fa-chevron-down"></i></a>

                <ul class="dropdown-menu" *ngIf="item.itemMenu">
                  <li class="nav-item" *ngFor="let subItem of item.itemMenu">
                    <a [routerLink]="subItem.url ? subItem.url: 'javascript:void(0)'"
                      class="nav-link">{{subItem.description}}</a>

                    <ul class="dropdown-menu" *ngIf="subItem.items">
                      <li class="nav-item" *ngFor="let subSubItem of subItem.items">
                        <a [routerLink]="subSubItem.url" class="nav-link" routerLinkActive="active"
                          [routerLinkActiveOptions]="{exact: true}">{{subSubItem.description}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="others-options">
              <a routerLink="/contact" class="btn btn-primary">{{data.textButton}}</a>
            </div>
          </div>
        </nav>
      </div>
    </div>


  </div>
</header>
