import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-single-card',
  templateUrl: './ts-single-card.component.html',
  styleUrls: ['./ts-single-card.component.scss']
})
export class TsSingleCardComponent {
  @Input() data: any;

  isExternalUrl(url: string) {
    return url && (url.startsWith('http://') || url.startsWith('https://'));
  }
}
