<div class="single-blog-post" *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <a [routerLink]="blogPosts.titleLink" class="post-image"><img [src]="blogPosts.imageSrc" alt="blog-image"></a>
  <div class="blog-post-content">
    <ul>
      <li><a [routerLink]="blogPosts.adminLink">Admin</a></li>
      <li><i class="far fa-clock"></i> {{blogPosts.date}}</li>
    </ul>
    <h3><a [routerLink]="blogPosts.titleLink">{{blogPosts.title}}</a></h3>
    <p>{{blogPosts.description}}</p>
    <a [routerLink]="blogPosts.readMoreLink" class="read-more-btn">{{blogPosts.readMoreText}} <i
        class="fas fa-angle-double-right"></i></a>
  </div>
</div>
