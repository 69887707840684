import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-card-post',
  templateUrl: './single-card-post.component.html',
  styleUrls: ['./single-card-post.component.scss'],
})
export class SingleCardPostComponent {
  @Input() data: any;

  validate(): boolean {
    return !!this.blogPosts && !!this.blogPosts.titleLink && !!this.blogPosts.imageSrc && !!this.blogPosts.adminLink && !!this.blogPosts.date && !!this.blogPosts.title && !!this.blogPosts.description && !!this.blogPosts.readMoreLink && !!this.blogPosts.readMoreText;
  }
  blogPosts = {
    imageSrc: 'assets/img/blog-img1.jpg',
    adminLink: '/blog-grid',
    date: 'January 23, 2023',
    titleLink: '/single-blog',
    title: 'I Used The Web For A Day On A 50 MB Budget',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.',
    readMoreLink: '/single-blog',
    readMoreText: 'Read More',
  };

}
