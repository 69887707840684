import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';




export const API_URI = environment.base_uri;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
constructor(private _router: Router, private _http: HttpClient) {}

authenticate(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    this._http.post(`${environment.base_uri}/websites/auth`, {}, { observe: 'response' }).subscribe(
      (response: HttpResponse<any>) => {
        const authHeader = response.headers.get('Authorization');
        if (authHeader) {
          const token = authHeader.split(' ')[1];
          localStorage.setItem('token', token);
          resolve();
        } else {
          console.log('No se encontró el encabezado de autorización');
          reject();
        }
      },
      (error) => {
        console.log('Error al hacer la petición POST:', error);
        reject();
      }
    );
  });
}

}