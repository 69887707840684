import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-services-box',
  templateUrl: './single-services-box.component.html',
  styleUrls: ['./single-services-box.component.scss'],
})
export class SingleServicesBoxComponent {
  @Input() data: any;

  validate(): boolean {
    return (
      !!this.data &&
      !!this.data.icon &&
      !!this.data.title &&
      !!this.data.paragraphs &&
      !!this.data.buttons
    );
  }
  /* services = [
    {
      icon: 'flaticon-router',
      title: 'Home Broadband',
      description:
        'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      link: '/single-services',
      buttonText: 'Read More',
    },
    {
      icon: 'flaticon-router',
      title: 'Home Broadband',
      description:
        'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      link: '/single-services',
      buttonText: 'Read More',
    },
     {
      icon: 'flaticon-router',
      title: 'Home Broadband',
      description:
        'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      link: '/single-services',
      buttonText: 'Read More',
    },
  ]; */
}
