import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-home-one',
  templateUrl: './ts-home-one.component.html',
  styleUrls: ['./ts-home-one.component.scss']
})
export class TsHomeOneComponent {
  @Input() data: any;
}
