<section class="offer-area ptb-100 jarallax" data-jarallax='{"speed": 0.2}' [ngClass]="data.class" loading="lazy">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
              <div class="offer-content">
                  <span class="sub-title" [ngClass]="data.subTitle.class">{{data.subTitle.text}}</span>
                  <h2 [ngClass]="data.title.class">{{data.title.text}}</h2>
                  <ul class="list">
                      <li *ngFor="let item of data.listItems"><i class="{{item.icon}}"></i> {{item.text}}</li>

                  </ul>
                  <div class="price">
                      <span class="old-price" [ngClass]="data.oldPrice.class">{{data.oldPrice.text}}</span>
                      <span class="new-price" [ngClass]="data.newPrice.class">{{data.newPrice.text}}</span>
                  </div>
                  <ul class="features-list">
                    <li *ngFor="let feature of data.features" [ngClass]="feature.class">{{feature.text}}</li>
                  </ul>
                  <a routerLink="/contact" class="btn btn-primary" [ngClass]="data.button.class">{{data.button.text}}</a>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
              <div class="offer-time">
                  <h2 [ngClass]="data.title2.class">{{data.title2.text}}</h2>
                  <div id="timer">
                      <div id="days">{{data.timer.days}} <span>Days</span></div>
                      <div id="hours">{{data.timer.hours}} <span>Hours</span></div>
                      <div id="minutes">{{data.timer.minutes}} <span>Minutes</span></div>
                      <div id="seconds">{{data.timer.seconds}} <span>Seconds</span></div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

