<div class="container-fluid">
  <div class="row justify-content-start">
    <div class="col-lg-12 col-md-12 margin-top-title-home" >
      <h1 [ngClass]="data.title.class">{{data.title.text}}</h1>
   <div class="image-container">
         <img *ngFor="let image of data.images" class="image-class" [ngClass]="image.class"  [src]="image.src" [alt]="image.alt">
   </div>
       <div class="overlay">
         <h2 [ngClass]="data.overlay.class" class="overlay-text">{{data.overlay.text}}</h2>
     </div>
     </div>
   </div>
 </div>





<!-- <div class="ts-container-fluid" loading="lazy" [ngClass]="data.class">
  <div class="row justify-content-start ts-home-image" >
    <div class="col-lg-12 col-md-12 offset-1">
     <h1 [ngClass]="data.title.class" class="h1-class ts-title-home">{{data.title.text}}</h1>
   <div class="image-container">
        <img *ngFor="let image of data.images" class="image-class" [ngClass]="image.class"  [src]="image.src" [alt]="image.alt">
         </div>
      <div class="overlay">
        <h2 [ngClass]="data.title.class" class="overlay-text">{{data.overlay.text}}</h2>
    </div>
    </div>
  </div>
</div>
 -->
