import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
} from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { NgxDeviceInfoService } from 'ngx-device-info';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuid } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './services/api.service';
import { WebsiteService } from './services/website.service';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
interface Analiticas {
  deviceName: string;
  orientation: string;
  deviceType: string;
  user_agent: string;
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  language: string;
  isOnline: boolean;
  cookieEnabled: boolean;
  vendor: string;
  clientHeight: number;
  clientWidth: number;
  height: number;
  width: number;
  availHeight: number;
  availWidth: number;
  innerHeight: number;
  innerWidth: number;
  outerHeight: number;
  outerWidth: number;
  href: string;
  protocal: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  search: string;
  hash: string;
  origin: string;
  timeZoneOffSet: number;
  utcString: string;
  time: number;
  date: number;
  day: number;
  month: number;
  hour: number;
  year: number;
  milliseconds: number;
  utcdate: number;
  utcday: number;
  utcmonth: number;
  utcyear: number;
  push: string;
  speaker: string;
  'device-info': string;
  bluetooth: string;
  'ambient-light-sensor': string;
  clipboard: string;
  nfc: string;
  geolocation: string;
  notifications: string;
  midi: string;
  camera: string;
  microphone: string;
  'background-fetch': string;
  'background-sync': string;
  'persistent-storage': string;
  accelerometer: string;
  gyroscope: string;
  magnetometer: string;
  'display-capture': string;
  referer: string;
}

interface AnaliticasDetector {
  browser: string;
  browser_version: string;
  os: string;
  os_version: string;
  device_type: string;
  orientation: string;
  user_agent: string;
  device: string;
}
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;
  currentUrl!: string;
  previousUrl!: string;
  public loading: boolean = false;
  title = 'ngx-cms';
  analiticasDetector!: AnaliticasDetector;
  analiticas!: Analiticas;
  website_uuid!: string;
  private routerEventsSubscription!: Subscription;

  constructor(
    private router: Router,
    private deviceInfoService: NgxDeviceInfoService,
    private deviceService: DeviceDetectorService,
    private renderer: Renderer2,
    private cookieService: CookieService,
    private _api: ApiService,
    private _websiteService: WebsiteService
  ) {
    this.loaderOnRouteChange();
  }

  ngOnInit() {
    this.recallJsFuntions();
    this.getCookie();
    this.subscribeToRouterEvents();
  }

  auth() {
    this._api.postWithFullResponse(`websites/auth `, {}).subscribe(
      (response: HttpResponse<any>) => {
        console.log(response);
        const authHeader = response.headers.get('Authorization');
        console.log('Authorization header:', authHeader);
        const body = JSON.parse(response.body);
        console.log('Response body:', body);
        if (authHeader) {
          const token = authHeader.split(' ')[1];
          localStorage.setItem('token', token);
        } else {
          this.router.navigate(['/404']);
        }
      },
      (error) => {
        console.log('Error al hacer la petición POST:', error);
        this.router.navigate(['/404']);
      }
    );
  }

  recallJsFuntions() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        $('.preloader').fadeIn('slow');
      }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        $.getScript('../assets/js/main.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  public loaderOnRouteChange(): void {
    console.log('AppComponent loaderOnRouteChange');
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationStart) {
        this.loading = true;
      }

      if (val instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }

  private checkAndSetUUID() {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith('/preview')) {
      console.log('Ruta de previsualización detectada, no se generará UUID');
      return;
    }

    let uuid_visitor = this.cookieService.get('UUID');
    console.log('UUID obtenido de la cookie:', uuid_visitor);
    if (!uuid_visitor) {
      this.createUUID();
    } else {
      console.log('UUID ya existente en la cookie');

      this.onSubmitEvents(uuid_visitor);
    }
  }

  private createUUID() {
    console.log('Generando nuevo UUID...');
    let uuid_visitor = uuid();
    console.log('Nuevo UUID generado:', uuid_visitor);
    this.onSubmit(uuid_visitor);
    this.cookieService.set('UUID', uuid_visitor);
    console.log('UUID almacenado en la cookie');
  }

  private setAnaliticasDetector(deviceDetector: any) {
    this.analiticasDetector = {
      browser: deviceDetector.browser ?? null,
      browser_version: deviceDetector.browser_version ?? null,
      os: deviceDetector.os ?? null,
      os_version: deviceDetector.os_version ?? null,
      device_type: deviceDetector.deviceType ?? null,
      orientation: deviceDetector.orientation ?? null,
      user_agent: deviceDetector.userAgent ?? null,
      device: deviceDetector.device ?? null,
    };
  }

  private setAnaliticas(deviceInfo: any) {
    let referrer = document.referrer;
    /*  console.log("aqui",referrer); */
    this.analiticas = {
      referer: referrer ?? null,
      deviceName: deviceInfo.deviceName ?? null,
      orientation: deviceInfo.orientation ?? null,
      deviceType: deviceInfo.deviceType ?? null,
      user_agent: deviceInfo.userAgent ?? null,
      isMobile: deviceInfo.isMobile ?? null,
      isDesktop: deviceInfo.isDesktop ?? null,
      isTablet: deviceInfo.isTablet ?? null,
      language: deviceInfo.language ?? null,
      isOnline: deviceInfo.isOnline ?? null,
      cookieEnabled: deviceInfo.cookieEnabled ?? null,
      vendor: deviceInfo.vendor ?? null,
      clientHeight: deviceInfo.clientHeight ?? null,
      clientWidth: deviceInfo.clientWidth ?? null,
      height: deviceInfo.height ?? null,
      width: deviceInfo.width ?? null,
      availHeight: deviceInfo.availHeight ?? null,
      availWidth: deviceInfo.availWidth ?? null,
      innerHeight: deviceInfo.innerHeight ?? null,
      innerWidth: deviceInfo.innerWidth ?? null,
      outerHeight: deviceInfo.outerHeight ?? null,
      outerWidth: deviceInfo.outerWidth ?? null,
      href: deviceInfo.href ?? null,
      protocal: deviceInfo.protocal ?? null,
      host: deviceInfo.host ?? null,
      hostname: deviceInfo.hostname ?? null,
      port: deviceInfo.port ?? null,
      pathname: deviceInfo.pathname ?? null,
      search: deviceInfo.search ?? null,
      hash: deviceInfo.hash ?? null,
      origin: deviceInfo.origin ?? null,
      timeZoneOffSet: deviceInfo.timeZoneOffSet ?? null,
      utcString: deviceInfo.utcString ?? null,
      time: deviceInfo.time ?? null,
      date: deviceInfo.date ?? null,
      day: deviceInfo.day ?? null,
      month: deviceInfo.month ?? null,
      hour: deviceInfo.hour ?? null,
      year: deviceInfo.year ?? null,
      milliseconds: deviceInfo.milliseconds ?? null,
      utcdate: deviceInfo.utcdate ?? null,
      utcday: deviceInfo.utcday ?? null,
      utcmonth: deviceInfo.utcmonth ?? null,
      utcyear: deviceInfo.utcyear ?? null,
      push: deviceInfo.push ?? null,
      speaker: deviceInfo.speaker ?? null,
      'device-info': deviceInfo['device-info'] ?? null,
      bluetooth: deviceInfo.bluetooth ?? null,
      'ambient-light-sensor': deviceInfo['ambient-light-sensor'] ?? null,
      clipboard: deviceInfo.clipboard ?? null,
      nfc: deviceInfo.nfc ?? null,
      geolocation: deviceInfo.geolocation ?? null,
      notifications: deviceInfo.notifications ?? null,
      midi: deviceInfo.midi ?? null,
      camera: deviceInfo.camera ?? null,
      microphone: deviceInfo.microphone ?? null,
      'background-fetch': deviceInfo['background-fetch'] ?? null,
      'background-sync': deviceInfo['background-sync'] ?? null,
      'persistent-storage': deviceInfo['persistent-storage'] ?? null,
      accelerometer: deviceInfo.accelerometer ?? null,
      gyroscope: deviceInfo.gyroscope ?? null,
      magnetometer: deviceInfo.magnetometer ?? null,
      'display-capture': deviceInfo['display-capture'] ?? null,
    };
    console.log('aqui', this.analiticas);
  }

  /* private subscribeToRouterEvents() {
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
   
    }

    Promise.all([
      this.deviceService.getDeviceInfo(),
      this.deviceInfoService.getDeviceInfo()
    ]).then(([deviceDetector, deviceInfo]) => {
      this.setAnaliticasDetector(deviceDetector);
      this.setAnaliticas(deviceInfo);
      this.checkAndSetUUID(); 
     
      
    });
  });
} */

  private subscribeToRouterEvents() {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        Promise.all([
          this.deviceService.getDeviceInfo(),
          this.deviceInfoService.getDeviceInfo(),
        ]).then(([deviceDetector, deviceInfo]) => {
          this.setAnaliticasDetector(deviceDetector);
          this.setAnaliticas(deviceInfo);

          this._websiteService.currentUuid
            .pipe(filter((uuid) => !!uuid))
            .subscribe((uuid) => {
              this.website_uuid = uuid;
              this.checkAndSetUUID();
            });

          this.routerEventsSubscription.unsubscribe();
        });
      }
    });
  }

  onSubmit(uuid_visitor: string) {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith('/preview')) {
      console.log('Ruta de previsualización detectada, no se generará UUID');
      return;
    }
    let body = {
      uuid: uuid_visitor,
      user_agent: this.analiticas.user_agent ?? null,
      os: this.analiticasDetector.os,
      browser: this.analiticasDetector.browser,
      device: this.analiticasDetector.device,
      deviceType: this.analiticasDetector.device_type,
      os_version: this.analiticasDetector.os_version,
      browser_version: this.analiticasDetector.browser_version,
      website_uuid: this.website_uuid,
    };
    console.log('body:', body);
    this._api.post(`visitors/create`, body).subscribe(
      (response) => {
        console.log(response);
        this.onSubmitFirstEvents(uuid_visitor);
      },
      (error) => {
        console.log('Error al hacer la petición POST:', error);
      }
    );
  }

  setCookie(value: string) {
    this.cookieService.set('authCookie', value);
  }

  getCookie() {
    const value: string = this.cookieService.get('authCookie');
    console.log('cookie de autenticacion', value);
  }

  onSubmitEvents(uuid_visitor: string) {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith('/preview')) {
      console.log('Ruta de previsualización detectada, no se generará UUID');
      return;
    }

    let body = {
      visitor_uuid: uuid_visitor,
      event_name: 'page_view',
      values: this.currentUrl,
      /* clipboard: this.analiticas.clipboard, */
      origin: this.analiticas.referer,
      href: this.analiticas.href,
      geolocation: this.analiticas.geolocation,
      notifications: this.analiticas.notifications,
      midi: this.analiticas.midi,
      nfc: this.analiticas.nfc,
      camera: this.analiticas.camera,
      microphone: this.analiticas.microphone,
      persistent_storage: this.analiticas['persistent-storage'],
      'device-info': this.analiticas['device-info'],
      'ambient-light-sensor': this.analiticas['ambient-light-sensor'],
    };
    console.log('bodyEvents:', body);
    this._api.post(`analitics-events`, body).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log('Error al hacer la petición POST:', error);
      }
    );
  }

  onSubmitFirstEvents(uuid_visitor: string) {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith('/preview')) {
      console.log('Ruta de previsualización detectada, no se generará UUID');
      return;
    }

    let body = {
      visitor_uuid: uuid_visitor,
      event_name: 'first_view',
      values: this.currentUrl,
      /* clipboard: this.analiticas.clipboard, */
      origin: this.analiticas.referer,
      href: this.analiticas.href,
      geolocation: this.analiticas.geolocation,
      notifications: this.analiticas.notifications,
      midi: this.analiticas.midi,
      nfc: this.analiticas.nfc,
      camera: this.analiticas.camera,
      microphone: this.analiticas.microphone,
      persistent_storage: this.analiticas['persistent-storage'],
      'device-info': this.analiticas['device-info'],
      'ambient-light-sensor': this.analiticas['ambient-light-sensor'],
    };
    console.log('bodyEvents:', body);
    this._api.post(`analitics-events`, body).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log('Error al hacer la petición POST:', error);
      }
    );
  }
}
