import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-offer-widget',
  templateUrl: './ts-offer-widget.component.html',
  styleUrls: ['./ts-offer-widget.component.scss']
})
export class TsOfferWidgetComponent {
  @Input() data: any;
}
