import { Component, Input, OnInit } from '@angular/core';
import { ScreenSizeService } from 'src/app/services/screen-size-service';
declare let jQuery: any;
declare let $: any;

@Component({
  selector: 'app-ts-header-one',
  templateUrl: './ts-header-one.component.html',
  styleUrls: ['./ts-header-one.component.scss']
})
export class TsHeaderOneComponent implements OnInit{
  isMobile$ : any;


   @Input() data: any;
   constructor(private screenSizeService: ScreenSizeService) {

  }

  ngOnInit(){

    this. initMenuMobile();
   /*  this. initMenuMobileScroll(); */
    this.isMobile$ = this.screenSizeService.isMobile();

}

  hasSubmenu(item: any): boolean {
    return item.itemMenu && item.itemMenu.filter((subItem:any) => subItem.url || subItem.description).length > 0;
  }

  initMenuMobile (){
    // Mean Menu
  jQuery(document).ready(function () {
    jQuery('header nav').meanmenu();
});



/*  $('header-nav').meanmenu({

  meanMenuContainer: ".vimi-mobile-nav",
  meanMenuClose: "X",
  meanMenuCloseSize: "18px",
  meanMenuOpen: "<span /><span /><span />",
  meanRevealPosition: "right",
  meanRevealPositionDistance: "0",
  meanRevealColour: "",
  meanScreenWidth: "480",
  meanNavPush: "",
  meanShowChildren: !0,
  meanExpandableChildren: !0,
  meanExpand: "+",
  meanContract: "-",
  meanRemoveAttrs: !1,
  onePage: !1,
  meanDisplay: "block",
  removeElements: ""



}); */
  }

/*   initMenuMobileScroll (){
    $(window).on('scroll',function() {
      console.log('scroll');

      jQuery('is-sticky').meanmenu();
    });
    // Mean Menu

  } */



  isExternalUrl(url: string) {
    const isExternal = url && (url.startsWith('http://') || url.startsWith('https://'));
    return isExternal;
  }

/*   "data": {
    "menu": [
        {
            "menu": "Inicio",
            "url": "/inicio",
            "class": "active"
          },
          {
            "menu": "Servicios",
            "url": "/servicios",
            "class": "active"
          },
          {
            "menu": "Nosotros",
            "url": "/nosotros",
            "class": "active"
          },
        {
            "url": "/contacto",
            "menu": "Contacto",
            "class": "primary"
        }
    ],
    "class": "bg-white",
    "buttons": [
        {
            "url": "\/button-2",
            "class": "btn btn-primary btn-green-ts me-2",
            "textButton": "SOLICITAR SERVICIO"
        },
        {
            "url": "\/button-2",
            "class": "btn btn-secondary btn-blue-ts",
            "textButton": "SOPORTE"
        }
    ],
    "imageSrc": "https:\/\/tecnosystem.net.ve\/wp-content\/uploads\/2019\/10\/Logo-Tecnosystem-io-300x63.png"
} */


}
