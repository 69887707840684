
<div class="single-features-box"  *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <div class="icon">
      <i [class]="data?.icon.class"></i>
  </div>
  <h3 [ngClass]="data?.title.class">{{data?.title.text}}</h3>
  <p *ngFor="let paragraph of data.paragraphs" [ngClass]="paragraph.class">{{paragraph.text}}</p>
  <div class="back-icon">
      <i [class]="data?.icon.name"></i>
  </div>
  <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
  <div class="image-box">
    <img *ngFor="let image of data.images" [src]="image.url" alt="image">
    <img *ngFor="let image of data.images" [src]="image.url" alt="image">
  </div>
</div>
