 <header class="header-area header-area-mobile" loading="lazy"
 *ngIf="isMobile$ | async; else desktopHeader">
  <div class="navbar-area navbar-area-ts" [ngClass]="data.class">
      <div class="vimi-mobile-nav">
          <div class="logo">
              <a routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>
          </div>
      </div>

      <div class="vimi-nav">
          <div class="container">
              <nav class="navbar navbar-expand-md navbar-light mean-menu">
                  <a class="navbar-brand" [routerLink]="data.url"  routerLink="/"></a>
                   <ul class="navbar-nav">
                      <li class="nav-item color-navbar" *ngFor="let item of data.menu">
                        <a [href]="item.url" class="nav-link ts-color-navbar" [attr.target]="isExternalUrl(item.url) ? '_blank' : null" >{{item.menu}} <i
                            class="fas fa-chevron-down" *ngIf="hasSubmenu(item)"></i></a>

                        <ul class="dropdown-menu dropdown-menu-ts" *ngIf="hasSubmenu(item)" [ngClass]="item.class">
                          <li class="nav-item" *ngFor="let subItem of item.itemMenu" [ngClass]="subItem.class">
                            <a [href]="subItem.url"[attr.target]="isExternalUrl(item.url) ? '_blank' : null" 
                              class="nav-link" [ngClass]="subItem.class">{{subItem.description}}</a>

                            <ul class="dropdown-menu dropdown-menu-ts" *ngIf="subItem.items">
                              <li class="nav-item" *ngFor="let subSubItem of subItem.items">
                                <a [href]="subSubItem.url" class="nav-link ts-color-navbar"
                                  >{{subSubItem.description}}</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                        <li class="nav-item" *ngFor="let item of data.buttons">
                          <a [href]="item.url"  class="nav-link ts-color-navbar"  [attr.target]="isExternalUrl(item.url) ? '_blank' : null" >{{item.textButton}}</a>
                        </li>


                    </ul>



              </nav>
          </div>
      </div>
  </div>
</header>

<ng-template #desktopHeader>
  <header class="header-area header-area-desktop" loading="lazy"
>
 <div class="navbar-area navbar-area-ts" [ngClass]="data.class">
     <div class="vimi-mobile-nav">
         <div class="logo">
             <a [routerLink]="data.url"><img [src]="data.imageSrc" alt="logo"></a>
         </div>
     </div>

     <div class="vimi-nav">
         <div class="container">
             <nav class="navbar navbar-expand-md navbar-light mean-menu">

                 <div class="collapse navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
                  <a class="navbar-brand" [routerLink]="data.url"  routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>
                  <ul class="navbar-nav">
                     <li class="nav-item color-navbar" *ngFor="let item of data.menu">
                       <a [routerLink]="item.url ? item.url : 'javascript:void(0)'" class="nav-link ts-color-navbar">{{item.menu}} <i
                           class="fas fa-chevron-down" *ngIf="hasSubmenu(item)"></i></a>

                       <ul class="dropdown-menu dropdown-menu-ts" *ngIf="hasSubmenu(item)" [ngClass]="item.class">
                         <li class="nav-item" *ngFor="let subItem of item.itemMenu" [ngClass]="subItem.class">
                           <a [routerLink]="subItem.url ? subItem.url: 'javascript:void(0)'"
                             class="nav-link" [ngClass]="subItem.class">{{subItem.description}}</a>

                           <ul class="dropdown-menu dropdown-menu-ts" *ngIf="subItem.items">
                             <li class="nav-item" *ngFor="let subSubItem of subItem.items">
                               <a [routerLink]="subSubItem.url" class="nav-link ts-color-navbar" routerLinkActive="active"
                                 [routerLinkActiveOptions]="{exact: true}">{{subSubItem.description}}</a>
                             </li>
                           </ul>
                         </li>
                       </ul>
                     </li>
                     <li class="nav-item custom-class" *ngFor="let item of data.buttons">
                      <a [href]="item.url"  class="nav-link ts-color-navbar margin-a-mobile"  [attr.target]="isExternalUrl(item.url) ? '_blank' : null" >{{item.textButton}}</a>
                    </li>
                   </ul>

                   <div class="others-options custom-class-buttons-header">
                     <a *ngFor="let item of data.buttons" [href]="item.url" [ngClass]="item.class" [attr.target]="isExternalUrl(item.url) ? '_blank' : null" >{{item.textButton}}</a>
                   </div>


                  </div>
             </nav>
         </div>
     </div>
 </div>
</header>
</ng-template>

<!-- <header>
	<nav style="display: block;" class="mean-nav">
		<ul>
			<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Top Level Link</a>
				<ul>
					<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Second Level Link</a>
						<ul>
							<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Third Level Link</a></li>
							<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Third Level Link</a></li>
							<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Third Level Link</a>
								<ul>
									<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fourth Level Link</a></li>
									<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fourth Level Link</a></li>
									<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fourth Level Link with extra long name so it wraps</a>
										<ul>
											<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fifth Level Link</a></li>
											<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fifth Level Link</a></li>
											<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Fifth Level Link</a></li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Second Level Link</a></li>
					<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Second Level Link</a></li>
				</ul>
			</li>
			<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Top Level Link</a></li>
			<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Top Level Link</a></li>
			<li><a href="https://www.meanthemes.com/demo/meanmenu/demo.html#">Top Level Link</a></li>
		</ul>
	</nav>
</header> -->

<!--  <header class="header-area" loading="lazy">
  <div class="navbar-area navbar-area-ts" [ngClass]="data.class">
      <div class="vimi-mobile-nav">
          <div class="logo">
              <a routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>
          </div>
      </div>

      <div class="vimi-nav">
          <div class="container">
              <nav class="navbar navbar-expand-md navbar-light">
                  <a class="navbar-brand" routerLink="/"><img [src]="data.imageSrc" alt="logo"></a>

                  <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                      <li class="nav-item color-navbar" *ngFor="let item of data.menu">
                        <a [routerLink]="item.url" class="nav-link ts-color-navbar">{{item.menu}}</a>
                      </li>
                    </ul>

                    <div class="others-options">
                      <a *ngFor="let item of data.buttons" [routerLink]="item.url" [ngClass]="item.class">{{item.textButton}}</a>
                    </div>
                  </div>
              </nav>
          </div>
      </div>
  </div>
</header> -->
