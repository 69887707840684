import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-about-content',
  templateUrl: './about-content.component.html',
  styleUrls: ['./about-content.component.scss'],
})
export class AboutContentComponent {
  @Input() data: any;

  validate(): boolean {
    return (
      !!this.data &&
      !!this.data.subTitle &&
      !!this.data.title &&
      !!this.data.paragraphs &&
      !!this.data.buttons
    );
  }

  /*   data = {
    subTitle: 'About Vimi',
    title: 'Experience the magic of tech to serve you a best',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisseLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.',
    nameButton: 'Learn More',
    link: '/about-us-1',
  }; */
}
