<div class="single-pricing-table ts-single-card" [ngClass]="data.class" loading="lazy">
  <div class="pricing-header">
    <div class="icon ts-icon">
      <i [class]="data.icon" style="color: white;"></i>
    </div>
  <!--   <span [ngClass]="data?.header?.class">[ngClass]="data?.header.class"</span> -->
    <h3 [ngClass]="data?.header?.class">{{data.header.title.text}}</h3>
  </div>
  <ul [ngClass]="data.listItems.class">
    <ng-container *ngFor="let item of data.listItems.items">
      <li class="color-list" *ngIf="item.item">
        <i class="flaticon-check-mark" [ngClass]="data.listItems.icon.class"></i> {{item.item}}
      </li>
    </ng-container>
  </ul>
  <div class="price">
    <span>{{data.detail.text1.value}}</span>
    <span>{{data.detail.text2.value}}</span>
    {{data.detail.text3.value}}
    <p class="text-white"> {{data.detail.text4.value}}</p>
  </div>
  <div *ngFor="let button of data?.buttons">
    <a *ngIf="button.active" [href]="button.link"  [ngClass]="button.class" [attr.target]="isExternalUrl(button.link) ? '_blank' : null" rel="noopener noreferrer">{{button.textButton}}</a>
  </div>
  <div class="image-box">
    <img [src]="data.image">
  </div>
</div>

