import { Component, Input, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit{
  imageSrc!: string;
  menu!: any[];
  textButton!: string;

  @Input() data: any;
  constructor() {
    console.log('constructor navbar');
  }

  ngOnInit(){

    this. initMenuMobile();
}

  validate(): boolean {
    return !!this.data?.imageSrc && !!this.data?.menu && this.data.menu.length > 0;
  }
  initMenuMobile (){
    // Mean Menu
 $('.mean-menu').meanmenu({
   meanScreenWidth: "1200"
 });

  }
  /*   ngOnInit(){
    this.dataService.getData().subscribe(data => {
      this.imageSrc = data.imageSrc;
      this.menu = data.menu;
      this.textButton = data.textButton;
    });
  } */

  /*   imageSrc = '';
  menu = [
    {
      menu: 'Home',
      itemMenu: [
        { description: 'Home 1', url: '/home-1' },
        { description: 'Home 2', url: '/home-1' },
      ],
    },
    {
      menu: 'Pages',
      itemMenu: [
        {
          description: 'About Us',
          items: [
            { description: 'About Us 1', url: '/About-Us-1' },
            { description: 'About Us 2', url: '/About-Us-2' },
          ],
        },
        { description: 'Team', url: '/team' },
        { description: 'Pricing', url: '/pricing' },
        { description: '404 Error', url: '/error' },
        { description: 'FAQ', url: '/faq' },
      ],
    },
    {
      menu: 'Services',
      itemMenu: [
        { description: 'Services Style 1', url: '/Services-1' },
        { description: 'Services Style 2', url: '/Services-2' },
        { description: 'Services Details', url: '/Services-details' },
      ],
    },
    {
      menu: 'Contact',
      url: '/contact',
    },
  ];
  textButton = 'Get Started'; */
}
