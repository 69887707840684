import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, from, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

constructor(private _authService: AuthService, private _router: Router) {}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        // Si el error es 401, intenta autenticar de nuevo
        return from(this._authService.authenticate()).pipe(
          switchMap(() => {
            const clonedRequest = req.clone({
              headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            });
            // Si la autenticación es exitosa, reintentar la solicitud clonada
            return next.handle(clonedRequest);
          })
        );
      } else {
      
        
        return throwError(error);
        
      }
    })
  );
}

}