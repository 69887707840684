import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ts-single-box',
  templateUrl: './ts-single-box.component.html',
  styleUrls: ['./ts-single-box.component.scss']
})
export class TsSingleBoxComponent {
  @Input() data: any;
  constructor(private router: Router) { } // Inyecta Router

  isExternalUrl(url: string) {
    return url && (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('mailto:'));
  }
  navigate(url: string) {
    if (this.isExternalUrl(url)) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }
}
