<div class="about-content" [ngClass]="data.class" loading="lazy">
  <span *ngIf="data?.subTitle?.text" class="sub-title" [ngClass]="data?.subTitle?.class"><i class="flaticon-care-about-environment"></i> {{data?.subTitle?.text}}</span>
  <h2 [ngClass]="data?.title?.class">{{data?.title?.text}}</h2>
  <p *ngFor="let paragraph of data?.paragraphs" [ngClass]="paragraph?.class" [innerHTML]="paragraph?.text"></p>
  <ng-container *ngFor="let button of data?.buttons">
    <a *ngIf="button?.active" [href]="button?.link"  [ngClass]="button?.class">{{button?.textButton}}</a>
  </ng-container>
</div>


