import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private uuidSource = new BehaviorSubject<string>('');
  currentUuid = this.uuidSource.asObservable();

  constructor() { }

  changeUuid(uuid: string) {
    this.uuidSource.next(uuid);
  }
}
