import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ts-title',
  templateUrl: './ts-title.component.html',
  styleUrls: ['./ts-title.component.scss']
})
export class TsTitleComponent {
  @Input() data: any;
}
