<div class="faq-accordion" *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <ul class="accordion">
    <ng-container *ngFor="let item of data.faqItems">
      <li class="accordion-item" *ngIf="item.title">
        <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add" [ngClass]="item.class"></i> {{item.title}}</a>
        <p class="accordion-content">{{item.content}}</p>
      </li>
    </ng-container>

  </ul>
</div>
