<section class="blog-area" [ngClass]="data.class" loading="lazy">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let item of data.post">
        <div class="single-blog-post pt-5">
          <a [routerLink]="item.link" class="post-image"><img [src]="item.image" alt="blog-image"></a>
          <div class="blog-post-content">
            <ul>
              <li><a [routerLink]="item.adminLink">{{ item.admin }}</a></li>
              <li><i class="far fa-clock"></i> {{ item.date }}</li>
            </ul>
            <h3><a [routerLink]="item.link">{{ item.title }}</a></h3>
            <p>{{ item.content }}</p>
            <a [routerLink]="item.link" class="read-more-btn">{{item.read}} <i class="fas fa-angle-double-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
