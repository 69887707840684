<div class="single-pricing-table" *ngIf="validate()" [ngClass]="data.class" loading="lazy">
  <div class="pricing-header">
    <div class="icon ts-bg-icon">
      <i [className]="data.header.icon.name"></i>
    </div>
    <span [ngClass]="data?.header.class">{{data.header.category.text}}</span>
    <h3 [ngClass]="data?.header.class">{{data.header.title.text}}</h3>
  </div>
  <ul class="" [ngClass]="data.listItems.class">
    <ng-container *ngFor="let item of data.listItems.items">
      <li *ngIf="item.item">
        <i [ngClass]="data.listItems.icon.class" class="flaticon-check-mark"></i> {{item.item}}
    </li>
    </ng-container>

  </ul>
  <div class="price">
    <span>{{data.detail.text1.value}}</span>
    <span>{{data.detail.text2.value}}</span>
    {{data.detail.text3.value}}
    <span> {{data.detail.text4.value}}</span>
  </div>
  <div *ngFor="let button of data?.buttons">
    <a *ngIf="button.active" [href]="button.link"  [ngClass]="button.class">{{button.textButton}}</a>
  </div>
  <div class="image-box">
    <img *ngFor="let image of data.images" [src]="image.url" alt="image">
    <img *ngFor="let image of data.images" [src]="image.url" alt="image">
  </div>
</div>

