<footer class="footer-area ts-footer-area" *ngIf="validate()" loading="lazy">
  <div class="container" [ngClass]="data.class">
    <div class="row">
      <div [ngClass]="section.class" *ngFor="let section of data?.footerSections">
        <div class="single-footer-widget">
          <h3 [ngClass]="section.title?.class">{{section.title?.text}}</h3>

          <div *ngIf="section.newsletter" class="d-flex flex-column align-items-center">
            <div class="logo">
              <a [routerLink]="section.url" [attr.target]="isExternalUrl(data.copyrightSection.policy.url) ? '_blank' : null"
              rel="noopener noreferrer"><img [src]="section.logo" alt="image" ></a>
              <p *ngFor="let desc of section.description" [ngClass]="desc.class">{{desc.text}}</p>
            </div>
            </div>

          <ul class="links-widget-list" *ngIf="section.items">
            <li *ngFor="let item of section.items">
              <!-- <a [href]="item.url" [ngClass]="item.class"  target="_blank" rel="noopener noreferrer"> -->
                <a [href]="item.url" [ngClass]="item.class" [attr.target]="isExternalUrl(item.url) ? '_blank' : null" rel="noopener noreferrer">
               <i *ngIf="item.icon" class="{{item.icon}}" aria-hidden="true"></i>   {{item.description}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area ts-copyright-area">
    <div class="container ts-container-copyright" >
        <p class="text-white p-cophyrigth">{{data.copyrightSection.textCopyright.text}}</p>
        <a class="text-white a-cophyrigth"
       [href]="data.copyrightSection.policy.url"
       [attr.target]="isExternalUrl(data.copyrightSection.policy.url) ? '_blank' : null"
       rel="noopener noreferrer">
      {{data.copyrightSection.policy.text}}
    </a>
    </div>
  </div>
</footer>
