import { ApiService } from './../../../services/api.service';
import { WebsiteService } from './../../../services/website.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forms-widget',
  templateUrl: './forms-widget.component.html',
  styleUrls: ['./forms-widget.component.scss']
})
export class FormsWidgetComponent {
  @Input() data: any;
  form!: FormGroup;
  sellers: any[] = [];
  plans: any[] = [];
  website_uuid!: string;
  public loading: boolean = false;
  isButtonDisabled: boolean = false;

  constructor(private fb: FormBuilder, private _websiteService: WebsiteService, private _api: ApiService,
    private router: Router) {
      console.log('constructor fortmulartio', this.router.url);
     
     
      const domain = window.location.protocol + '//' + window.location.hostname;
      this.isButtonDisabled = domain === environment.disableButtonRoute;
      console.log('Dominio completo:', domain);
   }

  ngOnInit() {
    console.log('ngOnInit fortmulartio'), this.router.url;
    this._websiteService.currentUuid.subscribe(uuid => {
      this.website_uuid = uuid;
      console.log("website-uuid",this.website_uuid);
    });
    this.getSellers();
    this.getPlans();
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      identification_number: ['', Validators.required],
      building: ['', Validators.required],
      apartment: ['', Validators.required],
      plan_uuid: ['', Validators.required],
      seller_uuid: ['', Validators.required],
      website_uuid: [this.website_uuid]
    });
  }
  getSellers() {
    this._api.get(`sellers?paginate=0&website_uuid=${this.website_uuid}&orderDirection=asc&orderBy=first_name&visible=true`).pipe(
      catchError((error) => {
        console.error('Error:', error.message || 'Unknown error');
        console.log('Error details:', error);
        return of(null); 
      })
    ).subscribe({
      next: ({ data }: any) => {
        console.log('data vendedores', data);
        this.sellers = data;
      },
      error: (error) => {
        console.error('Error:', error.message || 'Unknown error');
        console.log('Error details:', error);
      },
      complete: () => {
        console.log('getSellers completed');
      }
    });
  }
  
  getPlans() {
    this._api.get(`plans?paginate=0&website_uuid=${this.website_uuid}&orderDirection=asc&orderBy=name&visible=true`).pipe(
      catchError((error) => {
        console.error('Error:', error.message || 'Unknown error');
        console.log('Error details:', error);
        return of(null); 
      })
    ).subscribe({
      next: ({ data }: any) => {
        console.log('data planes:', data);
        this.plans = data;
      },
      error: (error) => {
        console.error('Error:', error.message || 'Unknown error');
        console.log('Error details:', error);
      },
      complete: () => {
        console.log('getPlans completed');
      }
    });
  }

  onSubmit() {
    console.log(this.form.value);
    if (this.form.valid) {
      let body = this.form.value;
      console.log(this.form.value);
      this.loading = true; 
      this._api.post(`data-forms`,  body).subscribe(
        (response) => {
          console.log(response);
          this.form.reset();
          Swal.fire({
            title: 'Exito',
            html: response.message,
            icon: 'success',
          });

          this.loading = false;
        },
        (error) => {
          this.form.reset();
          Swal.fire({
            title: 'Error',
            html: error.message,
            text: 'Inválido',
            icon: 'error',
          });
          console.log('Error al hacer la petición POST:', error);
          this.loading = false; 
        }
      );
    }
    else {
      Swal.fire({
        title: 'Error',
        html: 'Por favor, rellene todos los campos',
        icon: 'error',
      });
    }
  }

  checkButtonDisability() {
    
  }
}
