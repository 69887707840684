import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() data: any;
  validate(): boolean {
    return !!this.data?.footerSections && this.data.footerSections.length > 0;
  }


 /*  footerSections = [
    {
      logo: 'assets/img/logo.png',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sda.',
      newsletter: true
    },
    {
      title: 'Customer Services',
      items: [
        { description: 'My Vimi', url: '/' },
        { description: 'Vimi Media', url: '/' },
        { description: 'Connection and Configuration', url: '/' },
        { description: 'Support', url: '/' },
        { description: 'Video Tutorials', url: '/' },
        { description: 'Vimi Application', url: '/' }
      ]
    },
    {
      title: 'Useful Links',
      items: [
        { description: 'Coverage Map', url: '/' },
        { description: 'News', url: '/' },
        { description: 'Terms and Conditions', url: '/' },
        { description: 'License', url: '/' },
        { description: 'Testimonials', url: '/' },
        { description: 'Our Partners', url: '/' }
      ]
    },
    {
      title: 'Get In Touch',
      contactInfo: '+1 (800) 216 20 20 (Customer service and support)\n+1 (800) 216 20 20 (For new customers)\n1600 Harrison ave, office 203 New York, NY 309090'
    }
  ]; */
}
