<section class="error-area ptb-100" loading="lazy">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="error-content">
                  <img src="assets/img/mantenimiento.jpg" alt="error">
                  <h3>Página en Mantenimiento</h3>
                  <p>Estamos en mantenimiento, por favor intenta ingresar más tarde.</p>
                  <a routerLink="/" class="btn btn-primary">Home</a>
              </div>
          </div>
      </div>
  </div>
</section>
